import { Button, ConfigProvider, Drawer } from "antd";
import "./pageLayout.css";
import { Constants } from "../../constants";
import { ClipLoader } from "react-spinners";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import Sidebar from "./sidebar";

function PageLayout(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const dashBoard = location.pathname === "/";

  function goBack(): void {
    navigate(props.previousRoute.replace(/ /g, ''));
  }

  function toggleMobileSidebar() {
    setShowMobileSidebar(!showMobileSidebar);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Constants.COLOR_PRIMARY,
        },
      }}
    >
      <Drawer onClose={toggleMobileSidebar} open={showMobileSidebar} placement="left" width={'fit-content'}>
        <Sidebar />
      </Drawer>
      <div className="mainContainer">
        <div className="sidebar-main"><Sidebar /></div>
        <div className="container">
          <div className="content">
            <div className="content-navbar">
              <div className={dashBoard ? "header-left" : "header-custom-left"}>
                {props.previousRoute && (
                  <Button
                    type="text"
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    onClick={goBack}
                  />
                )}
                <div
                  className="header-titles"
                >
                  <div>
                    <h2>{props.title}</h2>
                    <h5>{props.subtitle}</h5>
                  </div>
                </div>
              </div>
              <div className="header-right">
                <div>{props.header}</div>
                <div className="sidebar-toggle-container">
                  <Button size="large" shape="circle" type="text" onClick={toggleMobileSidebar} className="toggle-sidebar-button">
                    <MenuOutlined className="toggle-sidebar-icon" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div>
                {props.isLoading ? (
                  <div className="loader-container">
                    <ClipLoader
                      color="#5271FA"
                      loading={true}
                      size={60}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  props.body
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default PageLayout;
