import { supabase } from './supabaseClient';
import { AuthService } from './authService';
import { IExam } from '../interfaces/exam';
import { UtilsService } from './utilsService';
import { ExamFilter } from '../enums/examFilter.enum';
import moment from 'moment';

export class ExamService {
    static async create(name: string, datetime: string, subjectId?: number): Promise<void> {
        const user = AuthService.getUser();
        const { error } = await supabase.from('exams').insert({ subject: subjectId, name, datetime, user: user.id });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async update(exam: IExam): Promise<void> {
        const body: any = { ...exam };
        body.subject = body.subject ? body.subject.id : null;
        delete body.id;
        const { error } = await supabase.from('exams').update({ ...body, passed: body.passed === undefined ? null : body.passed }).eq('id', exam.id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async delete(id: number): Promise<void> {
        const { error } = await supabase.from('exams').delete().eq('id', id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async getAll(filter: ExamFilter): Promise<IExam[]> {
        const user = AuthService.getUser();

        // Filter
        let query = supabase.from('exams').select('*, subject(*)').eq('user', user.id);
        const today = new Date().toISOString();
        switch (filter) {
            case ExamFilter.COMPLETED:
                query.lt('datetime', today);
                break;
            case ExamFilter.NOT_COMPLETED:
                query.gte('datetime', today);
                break;
            case ExamFilter.PASSED:
                query.eq('passed', true);
                break;
            case ExamFilter.NOT_PASSED:
                query.eq('passed', false);
                break;

        }

        const response = await query;
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async get(id: number): Promise<IExam> {
        const user = AuthService.getUser();
        const response = await supabase.from('exams').select('*, subject(*)').eq('id', id).eq('user', user.id).limit(1).single();
        if (response.error) {
            console.error(response.error);
            throw response.error;
        } else {
            return response.data;
        }
    }
}