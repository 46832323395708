import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { Difficulty } from "../../../../enums/difficulty.enum";
import dayjs from "dayjs";
import { UtilsService } from "../../../../services/utilsService";
import itIT from "antd/locale/it_IT";
import itITDayJs from "dayjs/locale/it";
import i18next, { t } from "i18next";
import "./createSimulationModal.css";
import { SimulationService } from "../../../../services/simulationService";
import { IExam } from "../../../../interfaces/exam";
import { QuestionsType } from "../../../../enums/questionsType.enum";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routes";
import { InfoCircleOutlined } from '@ant-design/icons';
import { SourceType } from "../../../../enums/sourceType";
import { IDocument } from "../../../../interfaces/document";
import { DocumentService } from "../../../../services/documentService";

export const locales: { [key: string]: any } = {
  it: itIT,
  en: null,
};

export const localesDayJs: { [key: string]: any } = {
  it: itITDayJs,
  en: null,
};

dayjs.locale(localesDayJs[i18next.language]);

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 20 },
};

export interface ISimulationForm {
  examId: number;
  totalQuestions: number;
  difficulty: Difficulty;
  questionsJson: string;
  type: QuestionsType;
  source: SourceType;
  document: number;
}

const CreateSimulationModal: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  exam: IExam;
  onCreate: any;
}) => {
  const [form] = Form.useForm();
  const [creating, setCreating] = useState(false);
  const [availableSimulations, setAvailableSimulations] = useState<number>(0);
  const [isDocumentSource, setIsDocumentSource] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const navigate = useNavigate();

  const loadAvailableSimulations = async () => {
    setAvailableSimulations(99999);
  }

  const loadDocuments = async () => {
    setDocuments((await DocumentService.getAll()).sort((a, b) => new Date(b.created_at as string).getTime() - new Date(a.created_at as string).getTime())); // SOrt date
  }

  useEffect(() => {
    loadAvailableSimulations();
    loadDocuments();
  }, []);

  const onChange = (value: any) => {
    setIsDocumentSource(form.getFieldValue('source') === SourceType.DOCUMENT);
  }

  const onFinish = async (values: ISimulationForm) => {
    // Check questions number
    if (values.totalQuestions > 10) {
      UtilsService.showErrorToast(
        t("pages.dashboard.exams.new_simulation_questions_error")
      );
      return;
    }
    values.examId = props.exam.id!;
    setCreating(true);
    UtilsService.showInfoToast(t('pages.dashboard.exams.creating_simulation_info'));
    try {
      const questionsJson: string = await SimulationService.generateQuestions(
        props.exam.name,
        props.exam.subject.name,
        values.totalQuestions,
        values.type,
        values.document ? 'https://zxhqpxctiqnrhubmgqdq.supabase.co/storage/v1/object/public/documents/' + documents.find((document) => document.id === values.document)?.file : undefined,
      );
      if (questionsJson) {
        values.questionsJson = JSON.stringify(questionsJson);
        const createdSimulations = await SimulationService.create(values);
        navigate(`/simulation?id=${createdSimulations[0].id}`);
        UtilsService.showSuccessToast(
          t("pages.dashboard.exams.create_simulation_success")
        );
        props.onCreate();
      } else {
        UtilsService.showErrorToast(t('generics.forms.login_access_error'));
      }
    } catch (e: any) { }
    setCreating(false);
  };

  return (
    <Modal
      title={t("pages.dashboard.exams.new_simulation_title")}
      footer={null}
      open={props.isOpen}
      onCancel={props.onClose}
    >
      <p className="exam-name">{props.exam.name}</p>
      <div className="modal-content">
        <div className="alert">

        </div>
        <div className="create-simulation-modal-info-container">
          <Popover content={t("pages.dashboard.exams.new_simulation_info")}>
            <Button type="text" shape="circle" icon={<InfoCircleOutlined className="info-icon" />} />
          </Popover>
        </div>
        <ConfigProvider locale={locales[i18next.language]}>
          <Form
            disabled={creating || availableSimulations === 0}
            {...layout}
            form={form}
            name="control-hooks"
            size="large"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            onChange={onChange}
          >
            <Form.Item
              name="source"
              label={t("pages.dashboard.exams.source")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={SourceType.GENERAL}>
                  {t("pages.dashboard.exams.source_general")}
                </Radio>
                <Radio value={SourceType.DOCUMENT}>
                  {t("pages.dashboard.exams.source_document")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {isDocumentSource && (
              <Form.Item

                name="document"
                rules={[{ required: true }]}
                label={t("pages.dashboard.exams.source_document")}>
                <Select
                  style={{ width: 120 }}
                  options={documents.map((document) => ({ value: document.id, label: document.name }))}
                />
              </Form.Item>
            )}
            <Form.Item
              name="totalQuestions"
              label={t("pages.dashboard.exams.questions_number")}
              rules={[{ required: true }]}
            >
              <InputNumber min="1" max="10" placeholder="1 - 10" />
            </Form.Item>
            <Form.Item
              name="type"
              label={t("pages.dashboard.exams.type")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={QuestionsType.OPEN}>
                  {t("pages.dashboard.exams.questions_type_open")}
                </Radio>
                <Radio value={QuestionsType.MULTIPLE}>
                  {t("pages.dashboard.exams.questions_type_multiple")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Divider />
            <div className="simulation-footer-container">
              <Button className="create-simulation-btn" type="primary" htmlType="submit" loading={creating} disabled={availableSimulations === 0}>
                {t("common.create")}
              </Button>
              <p className="text available-simulations-text">
                {availableSimulations > 0 && <div>{t('pages.dashboard.settings.available_simulations')}</div>}
                {availableSimulations === 0 && <div>{t('pages.dashboard.settings.no_available_simulations')} <a href={`/${AppRoutes.SETTINGS}`}>{t('pages.dashboard.settings.settings_page')}</a></div>}
              </p>
            </div>
          </Form>
        </ConfigProvider>
      </div>
    </Modal>
  );
};

export default CreateSimulationModal;
