import i18next, { t } from "i18next";
import "./DocumentsPage.css";
import PageLayout from "../../components/pageLayout/pageLayout";
import { useEffect, useState } from "react";
import { ISubject } from "../../interfaces/subject";
import { SubjectService } from "../../services/subjectService";
import { Button, Empty, Table, TableProps } from "antd";
import { IoMdAdd } from "react-icons/io";
import CreateSubjectModal from "../dashboard/modals/createSubject/CreateSubjectModal";
import { Link } from "react-router-dom";
import SubjectCard from "../../components/subjectCard/SubjectCard";
import { DocumentService } from "../../services/documentService";
import { IDocument } from "../../interfaces/document";
import CreateDocumentModal from "../dashboard/modals/createDocument/createDocument";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import 'moment/locale/it';
import DeleteDocumentModal from "../dashboard/modals/deleteDocument/deleteDocument";

moment.locale(i18next.language);

function DocumentsPage() {
    const columns: TableProps<IDocument>['columns'] = [
        {
            title: t('pages.dashboard.documents.table.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t('pages.dashboard.documents.table.file'),
            dataIndex: 'file',
            key: 'file',
            render: (file) => <a href={'https://zxhqpxctiqnrhubmgqdq.supabase.co/storage/v1/object/public/documents/' + file} target="_blank" rel="noreferrer" download>{file.split('/')[2]}</a>,
        },
        {
            title: t('pages.dashboard.documents.table.uploaded_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <span>{moment(text).format('dddd D MMMM yyyy - HH:mm')}</span>,
        },
        {
            title: t('pages.dashboard.documents.table.actions'),
            key: 'action',
            render: (_, record) => (
                <Button icon={<DeleteOutlined />} danger type="text" onClick={(e) => openDeleteModal(e, record)} />
            ),
        },
    ];

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [isDocumenttModalOpen, setisDocumentModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<IDocument>();

    function closeCreateModal() {
        setisDocumentModalOpen(false);
    }

    function openCreateModal() {
        setisDocumentModalOpen(true);
    }

    function openDeleteModal(event: any, document: IDocument) {
        setSelectedDocument(document);
        setIsDeleteModalOpen(true);
    }

    function closeDeleteModal() {
        setIsDeleteModalOpen(false);
    }

    function onDeleted() {
        closeDeleteModal();
        loadDocuments();
    }

    function onCreated() {
        closeCreateModal();
        loadDocuments();
    }

    function loadDocuments() {
        setIsLoading(true);
        DocumentService.getAll().then((documents) => {
            setDocuments(documents.sort((a, b) => a.created_at > b.created_at ? -1 : 1));
            setIsLoading(false);
        });
    }

    useEffect(() => {
        document.title = `PassMate | ${t("pages.dashboard.documents.title")}`;
        loadDocuments();
    }, []);

    return (
        <PageLayout
            isLoading={isLoading}
            title={t('pages.dashboard.documents.title')}
            subtitle={t('pages.dashboard.documents.subtitle')}
            header={
                <div className="filter-search">
                    <Button
                        className="navbar-btn"
                        type="primary"
                        icon={<IoMdAdd />}
                        onClick={openCreateModal}
                    >
                        {t("pages.dashboard.documents.add")}
                    </Button>
                </div>
            } body={
                <>
                    <CreateDocumentModal
                        isOpen={isDocumenttModalOpen}
                        onClose={closeCreateModal}
                        onCreate={onCreated}
                    ></CreateDocumentModal>
                    <DeleteDocumentModal
                        document={selectedDocument}
                        isOpen={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                        onUpdate={onDeleted}>
                    </DeleteDocumentModal>
                    {documents.length === 0 && (
                        <div className="empty-container">
                            <Empty description={t("pages.dashboard.documents.empty_data").split('\n').map(line => <p key={line}>{line}</p>)} />
                        </div>
                    )}
                    {documents.length > 0 && (
                        <Table<IDocument> columns={columns} dataSource={documents} />
                    )}
                </>
            }>
        </PageLayout>
    );
}

export default DocumentsPage;