import { useEffect, useState } from "react";
import { IExam } from "../../interfaces/exam";
import "./examCard.css";
import i18next, { t } from "i18next";
import moment from "moment";
import 'moment/locale/it';
import { Badge, Divider, Tag } from "antd";

const ExamCard: React.FC<IExam> = (exam) => {
  moment.locale(i18next.language);

  return (
    <div className="exam-card">
      <div className="upSide-card">
        <div className="exam-card-name">
          <div className="exam-result">
            {exam.passed === null && <Tag>{t('pages.dashboard.exams.exam_to_do')}</Tag>}
            {exam.passed !== null && exam.passed &&
              <Tag color="success" >{t('pages.dashboard.exams.passed')}</Tag>
            }
            {exam.passed !== null && !exam.passed && <Tag color="error" >{t('pages.dashboard.exams.not_passed')}</Tag>
            }
          </div>
          <h3>{exam.name}</h3>
          {exam.subject && <p className="text-sm">{exam.subject.name}</p>}
        </div>
      </div>
      <div className="divider"></div>
      <p className="date">{moment.utc(exam.datetime).local().calendar()}</p>
    </div>
  );
};

export default ExamCard;
