import { supabase } from './supabaseClient';
import { AuthService } from './authService';
import { UtilsService } from './utilsService';
import { ISimulation } from '../interfaces/simulation';
import { ISimulationForm } from '../pages/dashboard/modals/createSimulation/createSimulationModal';
import i18next from 'i18next';
import { SupabaseService } from './supabaseService';
import { AnswerItem } from '../pages/simulation/simulationPage';
import { Constants } from '../constants';
import { QuestionsType } from '../enums/questionsType.enum';

export class SimulationService {
    static async generateQuestions(examName: string, subjectName: string, totalQuestions: number, type: QuestionsType, documentUrl?: string): Promise<any> {
        let promptKey = 'api.exam_question_open';
        if (type === QuestionsType.MULTIPLE) {
            promptKey = 'api.exam_question_multiple';
        }
        const user = AuthService.getUser();
        let queryText = i18next.t(promptKey, { topics: examName, subjectName, totalQuestions, schoolType: UtilsService.getSchoolTypeString(user.user_metadata.school_type) });
        if (documentUrl) {
            queryText += '\n\n\n' + i18next.t('api.exam_question_document');
        }
        try {
            return JSON.parse((await SupabaseService.openAiSend(queryText, 'question', documentUrl)).data);
        } catch (e) {
            UtilsService.showErrorToast('Something went wrong');
        }
    }

    static async checkAnswers(examName: string, questions: string[], answers: string[], type: QuestionsType, documentUrl?: string): Promise<AnswerItem[]> {
        let promptKey = 'api.answer_check_open';
        if (type === QuestionsType.MULTIPLE) {
            promptKey = 'api.answer_check_multiple';
        }

        const questionsText = JSON.stringify(questions);
        const answersText = JSON.stringify(answers);
        let queryText = 'Questions: ' + questionsText + '\n\nAnswers: ' + answersText;
        queryText += '\n\n\n' + i18next.t(promptKey, { examName, maxPoints: Constants.MAX_ANSWER_POINTS });

        if (documentUrl) {
            queryText += i18next.t('api.answer_check_document');
        }

        const response = JSON.parse((await SupabaseService.openAiSend(queryText, 'answer', documentUrl)).data);
        return response.responses;
    }

    static async create(simulation: ISimulationForm): Promise<ISimulation[]> {
        const body = {
            exam: simulation.examId,
            difficulty: simulation.difficulty,
            total_questions: simulation.totalQuestions,
            questions_json: simulation.questionsJson,
            type: simulation.type,
            document: simulation.document,
        }
        const { error, data } = await supabase.from('simulations').insert(body).select();
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            return data;
        }
    }

    static async getAll(examId: number): Promise<ISimulation[]> {
        const user = AuthService.getUser();
        const response = await supabase.from('simulations').select('*, exam(*), document(*)').eq('exam', examId).eq('exam.user', user.id).order('created_at', { ascending: false });
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async get(id: number): Promise<ISimulation> {
        const user = AuthService.getUser();
        const response = await supabase.from('simulations').select('*, exam!inner(*), document(*)').eq('id', id).eq('exam.user', user.id).limit(1).single();
        if (response.error) {
            console.error(response.error);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async delete(simulationIds: number[]): Promise<void> {
        const response = await supabase.from('simulations').delete().in('id', simulationIds)
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        }
    }

    static async update(simulation: ISimulation): Promise<void> {
        const body: any = { ...simulation };
        delete body.document;
        body.exam = simulation.exam.id;
        delete body.id;
        const { error } = await supabase.from('simulations').update(body).eq('id', simulation.id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }
}