import { t } from "i18next";
import PageLayout from "../../components/pageLayout/pageLayout";
import { useEffect, useState } from "react";
import { ISubject } from "../../interfaces/subject";
import { SubjectService } from "../../services/subjectService";
import { Button, Empty } from "antd";
import { IoMdAdd } from "react-icons/io";
import CreateSubjectModal from "../dashboard/modals/createSubject/CreateSubjectModal";
import { Link } from "react-router-dom";
import SubjectCard from "../../components/subjectCard/SubjectCard";

function SubjectsPage() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const [isSubjectModalOpen, setIsSubjectModalOpen] = useState<boolean>(false);

    function closeSubjectModal() {
        setIsSubjectModalOpen(false);
    }

    function openSubjectModal() {
        setIsSubjectModalOpen(true);
    }

    function onSubjectCreated() {
        closeSubjectModal();
        loadSubjects();
    }

    function loadSubjects() {
        setIsLoading(true);
        SubjectService.getAll().then((subjects) => {
            setSubjects(subjects);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        document.title = `PassMate | ${t("pages.dashboard.subjects.title")}`;
        loadSubjects();
    }, []);

    return (
        <PageLayout
            isLoading={isLoading}
            title={t('pages.dashboard.subjects.title')}
            subtitle={t('pages.dashboard.subjects.subtitle')}
            header={
                <div className="filter-search">
                    <Button
                        className="navbar-btn"
                        type="primary"
                        icon={<IoMdAdd />}
                        onClick={openSubjectModal}
                    >
                        {t("pages.dashboard.subjects.add")}
                    </Button>
                </div>
            } body={
                <>
                    <CreateSubjectModal
                        isOpen={isSubjectModalOpen}
                        onClose={closeSubjectModal}
                        onCreate={onSubjectCreated}
                    ></CreateSubjectModal>
                    {subjects.length === 0 && (
                        <div className="empty-container">
                            <Empty description={t("pages.dashboard.subjects.empty_data").split('\n').map(line => <p key={line}>{line}</p>)} />
                        </div>
                    )}
                    {subjects.length > 0 && (
                        <div className="subjects-container">
                            {subjects.map((subject) => (
                                <SubjectCard subject={subject} onUpdate={loadSubjects}></SubjectCard>
                            ))}
                        </div>
                    )}
                </>
            }>
        </PageLayout >
    );
}

export default SubjectsPage;