import { Avatar, Button } from "antd";
import Logo from "../logo/logo";
import { CopyOutlined, ReadOutlined, HomeOutlined, UserOutlined, SettingFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router";
import { AuthService } from "../../services/authService";
import { Constants } from "../../constants";
import { AppRoutes } from "../../routes/routes";
import { useEffect, useState } from "react";
import { t } from "i18next";

function Sidebar() {
    const user = AuthService.getUser();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/' || location.pathname.includes('exam') || location.pathname.includes('simulation')) {
            setPage(1);
        } else if (location.pathname === '/subjects') {
            setPage(2);
        } else if (location.pathname === '/documents') {
            setPage(3);
        } else {
            setPage(0);
        }
    }, [location.pathname]);

    function goToPage(event: any, pageNumber: number): void {
        setPage(pageNumber);
        switch (pageNumber) {
            case 1:
                navigate('/');
                break;
            case 2:
                navigate('/subjects');
                break;
            case 3:
                navigate('/documents');
                break;
            default:
                break;
        }
    }

    async function onSettingsMenuClick(event: any) {
        navigate(`/${AppRoutes.SETTINGS}`);
    }

    return <div className="sidebar">
        <div className="sidebar-body">
            <div className="sidebarLogo">
                <Logo />
            </div>
            <Button icon={<HomeOutlined />} onClick={(e) => goToPage(e, 1)} size="large" className="sidebarButton" type={page === 1 ? 'primary' : 'text'}>{t('pages.dashboard.sidebar.exams')}</Button>
            <Button icon={<ReadOutlined />} onClick={(e) => goToPage(e, 2)} size="large" className="sidebarButton" type={page === 2 ? 'primary' : 'text'}>{t('pages.dashboard.sidebar.subjects')}</Button>
            <Button icon={<CopyOutlined />} onClick={(e) => goToPage(e, 3)} size="large" className="sidebarButton" type={page === 3 ? 'primary' : 'text'}>{t('pages.dashboard.sidebar.documents')}</Button>
        </div>
        <div className="sidebarFooter">
            <div className="avatar-box">
                <div className="avatar">
                    <Avatar
                        size={50}
                        icon={<UserOutlined />}
                        src={
                            user.user_metadata && user.user_metadata.avatar_url && (
                                <img
                                    src={`${user.user_metadata.avatar_url
                                        }?${new Date().getTime()}`}
                                    alt="avatar"
                                />
                            )
                        }
                    />
                    <div className="avatar-names">
                        <h3 className="avatar-name">{user.user_metadata.full_name}</h3>
                        <p className="avatar-subtitle" >{t('generics.school_types.' + user.user_metadata.school_type)}</p>
                    </div>
                </div>
                <Button icon={<SettingFilled style={{ color: Constants.COLOR_PRIMARY }} />} size="large" type="text" onClick={onSettingsMenuClick}></Button>
            </div>
        </div>
    </div>
}

export default Sidebar;