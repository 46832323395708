import { Alert, Button, ConfigProvider, Form, Input, Modal, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import "./createDocument.css";
import { UtilsService } from "../../../../services/utilsService";
import { IDocument } from "../../../../interfaces/document";
import { DocumentService } from "../../../../services/documentService";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import itIT from 'antd/locale/it_IT';
import i18next from "i18next";
import { useState } from "react";
import { ClipLoader } from "react-spinners";

export const locales: { [key: string]: any } = {
  it: itIT,
  en: null
}

interface IDocumentForm {
  name: string;
  file: File;
}

const CreateDocumentModal: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  document?: IDocument;
  onCreate: any;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [creating, setCreating] = useState(false);
  const [file, setFile] = useState<File>();

  const onCreate = async (values: IDocumentForm) => {
    try {
      setCreating(true);
      await DocumentService.create(values.name, file!);
      UtilsService.showSuccessToast(
        t("modals.create.document_creating_success")
      );
      form.setFieldValue("name", "");
      form.setFieldValue("content", "");
      props.onCreate();
      props.onClose();
      setCreating(false);
    } catch (e: any) {
      setCreating(false);
      console.error(e);
      UtilsService.showErrorToast(e.message);
    }
  };

  const uploadProperties: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.pdf',
    beforeUpload(file) {
      form.setFieldValue("file", file);
      setFile(file);
    },
  };

  return (
    <Modal
      open={props.isOpen}
      title={
        t("modals.create.document_title")
      }
      onCancel={props.onClose}
      footer={null}
      className="create-subject-modal"
    >
      {creating && (<ClipLoader
        color="#5271FA"
        loading={true}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />)}
      {!creating && (<ConfigProvider locale={locales[i18next.language]}>
        <Form
          form={form}
          name="control-hooks"
          size="large"
          onFinish={onCreate}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="name"
            label={t("modals.create.subject_name")}
            rules={[{ required: true }]}
            initialValue={props.document?.name}
            className="create-subject-form-input"
          >
            <Input
              maxLength={50}
              minLength={6}
              placeholder={t("modals.create.name_suggestion")}
              className="create-subject-input"
            />
          </Form.Item>
          <Form.Item name="file" rules={[{ required: true }]}>
            <Dragger {...uploadProperties}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('pages.dashboard.documents.upload_title')}</p>
              <p className="ant-upload-hint">
                {t('pages.dashboard.documents.upload_description').split('\n').map(line => <p key={line}>{line}</p>)}
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Alert message={t('pages.dashboard.documents.upload_warning')} type="warning" showIcon />
          </Form.Item>
          <Form.Item>
            <Button
              key="create"
              type="primary"
              htmlType="submit"
              className="create-subject-btn"
            >
              {t("common.add")}
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>)}
    </Modal>
  );
};

export default CreateDocumentModal;
