import React, { Fragment } from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import { BrowserRouter as Router, Route, Routes, useSearchParams } from "react-router-dom";
import "./assets/css/fonts.css";
import "./assets/css/shared.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/login/Login";
import SignUp from "./pages/signUp/SignUp";
import ExamPage from "./pages/exam/examPage";
import SimulationPage from "./pages/simulation/simulationPage";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PrivateRoute from "./routes/PrivateRoute";
import NotFound from "./pages/404/NotFound";
import GoogleSignInPage from "./pages/google-sign-in/googleSignIn";
import { AppRoutes } from "./routes/routes";
import SettingsPage from "./pages/settings/settingsPage";
import { AuthService } from "./services/authService";
import moment from "moment";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import SubjectsPage from "./pages/subjects/SubjectsPage";
import DocumentsPage from "./pages/documents/DocumentsPage";

const App: React.FC = () => {
  AuthService.checkUserSession();

  // Timezone setup
  var tzMoment = moment.tz.guess();
  moment.tz.setDefault(tzMoment);
  dayjs.extend(utc)
  dayjs.extend(tz)
  const timeZone = dayjs.tz.guess()
  dayjs.tz.setDefault(timeZone)

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/subjects" element={<SubjectsPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/exam" element={<ExamPage />} />
            <Route path="/simulation" element={<SimulationPage />} />
            <Route path={AppRoutes.SETTINGS} element={<SettingsPage />} />
          </Route>
          <Route path={AppRoutes.SIGN_IN} element={<Login />} />
          <Route path={AppRoutes.SIGN_UP} element={<SignUp />} />
          <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={AppRoutes.GOOGLE_SIGN_IN} element={<GoogleSignInPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </Fragment>
    </Router>
  );
};

export default App;
