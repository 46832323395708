import { Button, Modal } from "antd";
import { useState } from "react";
import { UtilsService } from "../../../../services/utilsService";
import "./deleteDocument.css";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../../../interfaces/document";
import { DocumentService } from "../../../../services/documentService";

const DeleteDocumentModal: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  onUpdate: any;
  document: IDocument;
}) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    document: IDocument
  ) => {
    setIsDeleting(true);
    try {
      await DocumentService.delete(document.id);
      UtilsService.showSuccessToast(t("modals.delete.document_deleting_success"));
      props.onUpdate();
    } catch (e) { }
    setIsDeleting(false);
  };

  return (
    <Modal
      open={props.isOpen}
      title={t("modals.delete.document_title")}
      onCancel={props.onClose}
      footer={[
        <Button onClick={props.onClose}>{t("common.return")}</Button>,
        <Button
          danger
          type="primary"
          loading={isDeleting}
          onClick={(event) => onDelete(event, props.document)}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      <p>
        {t("modals.delete.document_confirm_delete", {
          subjectNme: props.document?.name,
        })}
      </p>
    </Modal>
  );
};

export default DeleteDocumentModal;
