import { supabase } from './supabaseClient';
import { AuthService } from './authService';
import { UtilsService } from './utilsService';
import { IDocument } from '../interfaces/document';

export class DocumentService {
    static async create(name: string, file: File): Promise<void> {
        const user = AuthService.getUser();

        let documentUrl;
        try {
            const { data } = await supabase
                .storage
                .from('documents')
                .upload(user.id + '/' + new Date().getTime() + '/' + file.name, file, {
                    cacheControl: '3600',
                    upsert: true,
                    contentType: file.type,
                });
            documentUrl = data;
        } catch (e: any) {
            UtilsService.showErrorToast(e.message);
            return;
        }

        const { error } = await supabase.from('documents').insert({ name, file: documentUrl?.path, user: user.id });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async delete(id: number): Promise<void> {
        const { error } = await supabase.from('documents').delete().eq('id', id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async getAll(): Promise<IDocument[]> {
        const user = AuthService.getUser();
        const response = await supabase.from('documents').select('*').eq('user', user.id);
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async get(id: number): Promise<IDocument> {
        const response = await supabase.from('documents').select('*').eq('id', id).limit(1).single();
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }
}